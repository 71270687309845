import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { UserDto } from '../../../../../libs/data/src/lib/dto/user.dto';

@Injectable({ providedIn: 'root' })
export class AuthService {

    private apiUrl = environment.url + environment.api;
    private user: BehaviorSubject<UserDto|null> = new BehaviorSubject<UserDto|null>(null);

    constructor(
        @Inject(DOCUMENT) private document: Document,
        protected cookieService: CookieService,
        private http: HttpClient,
    ) {
    }

    public signIn() {
        this.document.location.href = this.apiUrl + '/auth/microsoft/login';
    }

    public signOut() {
        this.cookieService.delete('access_token');
        this.updateUser(null);
        this.document.location.href = '/'
    }

    public signOutXero$(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/logout');
    }

    public getUser$(): BehaviorSubject<UserDto|null> {
        return this.user;
    }

    public isAuthorised$(): Observable<boolean> {
        return this.user.pipe(map((user) => !!user && !!user.microsoftAuth));
    }

    public isAdmin$(): Observable<boolean> {
        return this.user.pipe(map((user) => !!user && user.userRole === 1));
    }

    public getCurrent$(): Observable<UserDto> {
        return this.http.get<UserDto>(this.apiUrl + '/auth/current');
    }

    public getCurrentUser$(): Observable<UserDto> {

        return this.getCurrent$().pipe(
            map((user) => {
                this.updateUser(user);
                return user;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === HttpStatusCode.Unauthorized) {
                    this.updateUser(null);
                }
                return throwError(() => new Error(error.message));
            })
        );
    }

    public updateUser(user: UserDto | null) {
        this.user.next(user);
    }
}
