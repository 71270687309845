import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class ApiService {

    private apiUrl = environment.url + environment.api;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        protected cookieService: CookieService,
        private http: HttpClient,
    ) {

    }

    public login(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/login');
    }

    public getConnections(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/connections');
    }

    public getOrganisation(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/organisation');
    }

    public getItems(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/items').pipe(
            map(items => items.Items)
        );
    }

    public getAllItems(): Observable<Array<object>> {
        return this.http.get<any>(this.apiUrl + '/items').pipe(
            map(items => items)
        );
    }

    public getAllInvoices(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/invoices').pipe(
            map(invoices => invoices)
        );
    }

    public getInvoiceLineItems(invoiceID: string): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/invoice-line-items/invoice/' + invoiceID).pipe(
            map(invoices => invoices)
        );
    }

    public getItemIDLineItems(itemID: string): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/invoice-line-items/item/' + itemID).pipe(
            map(invoices => invoices)
        );
    }

    public getItem(id: string): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/items/' + id).pipe(
            map(item => item)
        );
    }

    public syncItems(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/items-sync').pipe(
            map(items => items.Items)
        );
    }

    public syncInvoices(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/invoices-sync').pipe(
            map(items => items.Items)
        );
    }

    public syncInvoiceLineItems(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/xero/invoice-line-items-sync').pipe(
            map(items => items.Items)
        );
    }
}
