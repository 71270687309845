import { Route } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { XeroAuthGuard } from './guards/xero-auth.guard';
import { UserAuthGuard } from './guards/user-auth.guard';
import { LoginComponent } from './components/login/login.component';
import { InvoicesComponent } from './components/invoices/invoices.component';

export const appRoutes: Route[] = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'inventory',
        component: InventoryComponent,
        canActivate: [ XeroAuthGuard ]
    },
    {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [ XeroAuthGuard ]
    },
    {
        path: '',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [ UserAuthGuard ]
    },
    {
        path: '**',
        redirectTo: '/login'
    },
];
