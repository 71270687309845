<div class="row mt-2">
    <div class="col">
        <span class="logo">cobrel</span>
    </div>
    <div class="col d-flex justify-content-end align-items-end">
        <h3 class="mb-3">Inventory</h3>
    </div>
</div>

<hr class="mb-4"/>

<div class="row">
    <div class="col">

        <form>
            <div class="mb-4 row">
                <div class="col-2">
                    <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                        <option [ngValue]="5">5 items per page</option>
                        <option [ngValue]="10">10 items per page</option>
                        <option [ngValue]="15">15 items per page</option>
                        <option [ngValue]="25">25 items per page</option>
                    </select>
                </div>

<!--                <div class="col d-flex align-items-center">-->
<!--                    @if (service.loading$ | async) {-->
<!--                        Loading...-->
<!--                    }-->
<!--                </div>-->

                <div class="col-8 d-flex justify-content-center">
                    <ngb-pagination
                        [collectionSize]="(total$ | async)!"
                        [(page)]="service.page"
                        [pageSize]="service.pageSize"
                        [maxSize]="10"
                        [rotate]="true"
                        [ellipses]="true"
                        [boundaryLinks]="true">
                    </ngb-pagination>
                </div>

                <div class="col-2">
                    <input
                        id="table-complete-search"
                        type="text"
                        class="form-control"
                        name="searchTerm"
                        placeholder="Search..."
                        [(ngModel)]="service.searchTerm"
                    />
                </div>
            </div>

            <table class="table table-striped table-sm" style="font-size: 0.9rem">
                <thead>
                <tr>
                    <th class="text-nowrap" scope="col" sortable="code" (sort)="onSort($event)">Code</th> <!-- {{ service.sortColumn === 'code' ? service.sortDirection === 'asc' ? 'asc' : 'des' : '' }} -->
                    <th class="text-nowrap" scope="col" sortable="name" (sort)="onSort($event)">Name</th>
                    <th class="text-nowrap" scope="col" sortable="description" (sort)="onSort($event)">Sales Description</th>
                    <th class="text-nowrap" scope="col" sortable="description" (sort)="onSort($event)">Purchase Description</th>
                    <th class="text-nowrap" scope="col" sortable="purchaseUnitPrice" (sort)="onSort($event)">Purchase Price</th>
                    <th class="text-nowrap" scope="col" sortable="salesUnitPrice" (sort)="onSort($event)">Unit Price</th>
                    <th class="text-nowrap" scope="col" sortable="quantityOnHand" (sort)="onSort($event)">Qty</th>
                </tr>
                </thead>
                <tbody>
                    @for (country of countries$ | async; track country.id) {
                        <tr>
                            <td>
                                <a class="link-offset-2 link-underline link-underline-opacity-25" role="button" (click)="clickItem(country, content)">
                                    <ngb-highlight [result]="country.code" [term]="service.searchTerm" />
                                </a>
                            </td>
                            <td>
                                <ngb-highlight [result]="country.name" [term]="service.searchTerm" />
                            </td>
                            <td>
                                <span
                                    class="d-inline-block text-truncate"
                                    style="max-width: calc( 20 * 1vw )"
                                    [ngbTooltip]="country.description"
                                >
                                    <ngb-highlight [result]="country.description" [term]="service.searchTerm" />
                                </span>
                            </td>
                            <td>
                                <span
                                    class="d-inline-block text-truncate"
                                    style="max-width: calc( 20 * 1vw )"
                                    [ngbTooltip]="country.description"
                                >
                                    <ngb-highlight [result]="country.purchaseDescription" [term]="service.searchTerm" />
                                </span>
                            </td>
                            <td>
                                <ngb-highlight [result]="country.purchaseUnitPrice | number" [term]="service.searchTerm" />
                            </td>
                            <td>
                                <ngb-highlight [result]="country.salesUnitPrice | number" [term]="service.searchTerm" />
                            </td>
                            <td>
                                <ngb-highlight [result]="country.quantityOnHand | number" [term]="service.searchTerm" />
                            </td>
                        </tr>
                    } @empty {
                        <tr>
                            <td colspan="7" style="text-align: center">No items found</td>
                        </tr>
                    }
                </tbody>
            </table>

        </form>
    </div>
</div>

<div class="row">
    <div class="col">
        <br/>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary" (click)="clickSync()">Sync Items</button>
        </div>
    </div>
</div>

<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <h4 class="offcanvas-title">{{ selectedItem?.code }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <table class="table table-striped table-sm" style="font-size: 0.9rem">
            <thead>
            <tr>
                <th class="text-nowrap" scope="col">Invoice</th>
                <th class="text-nowrap" scope="col">Date</th>
                <th class="text-nowrap" scope="col">Contact</th>
                <th class="text-nowrap" scope="col">Qty</th>
                <th class="text-nowrap" scope="col">Price</th>
                <th class="text-nowrap" scope="col">Rep</th>
            </tr>
            </thead>
            <tbody>
                @for (lineItem of itemIDLineItems; track lineItem.id) {
                    <tr>
                        <td>
                            <a class="link-offset-2 link-underline link-underline-opacity-25" role="button" href="https://go.xero.com/app/!VfSQ9/invoicing/view/{{ lineItem.invoiceID }}" target="_blank">
                                {{ lineItem.invoiceNumber }}
                            </a>
                        </td>
                        <td>
                            {{ lineItem.invoiceDate | date: 'dd/MM/yy' }}
                        </td>
                        <td>
                            <span
                                class="d-inline-block text-truncate"
                                style="max-width: calc( 15 * 1vw )"
                                [ngbTooltip]="lineItem.contactName"
                            >
                                {{ lineItem.contactName }}
                            </span>
                        </td>
                        <td>
                            {{ lineItem.quantity | number: '1.4-4' }}
                        </td>
                        <td>
                            {{ lineItem.unitAmount | number: '1.2-2' }}
                        </td>
                        <td>
                            {{ lineItem.trackingRep }}
                        </td>
                    </tr>
                } @empty {
                    <tr>
                        <td colspan="6">No invoice line items found for this item code.</td>
                    </tr>
                }
            </tbody>
        </table>
    </div>
</ng-template>
