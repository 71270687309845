import { Component, inject, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { ApiService } from '../../services/api.service';
import {
    NgbHighlight,
    NgbOffcanvas,
    NgbOffcanvasRef,
    NgbPaginationModule,
    NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { InventoryService } from '../../services/inventory.service';
import { NgbdSortableHeader, SortEvent } from '../../directives/sortable.directive';
import { FormsModule } from '@angular/forms';
import { ItemDto } from '../../../../../../libs/data/src/lib/dto/item.dto';
import { InvoiceLineItemDto } from '../../../../../../libs/data/src/lib/dto/invoice-line-item.dto';

@Component({
    selector: 'app-inventory',
    imports: [
        AsyncPipe,
        CommonModule,
        FormsModule,
        NgbHighlight,
        NgbPaginationModule,
        NgbdSortableHeader,
        NgbTooltipModule,
    ],
    providers: [
        ApiService,
        InventoryService,
        DecimalPipe
    ],
    templateUrl: './inventory.component.html',
    styleUrl: './inventory.component.scss'
})
export class InventoryComponent implements OnInit {
    countries$: Observable<ItemDto[]>;
    total$: Observable<number>;

    selectedItem: ItemDto | null = null;
    itemIDLineItems: InvoiceLineItemDto[] = []

    private offcanvasService = inject(NgbOffcanvas);
    private offcanvasRef: NgbOffcanvasRef | null = null;

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> | undefined;

    constructor(
        private apiService: ApiService,
        public service: InventoryService
    ) {
        this.countries$ = service.countries$;
        this.total$ = service.total$;
    }

    ngOnInit() {
    }

    onSort({ column, direction }: SortEvent) {

        this.headers?.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.sortColumn = column;
        this.service.sortDirection = direction;
    }

    clickSync() {
        this.apiService.syncItems().subscribe(() => {

        })
    }

    clickItem(item: ItemDto, content: TemplateRef<any>) {
        this.selectedItem = item;
        this.apiService.getItemIDLineItems(item.itemID).subscribe((response) => {
            this.itemIDLineItems = response;
            if (!this.offcanvasRef) {
                this.offcanvasRef = this.offcanvasService.open(content, { position: 'end', backdrop: false, panelClass: 'offcanvas-panel' });
                this.offcanvasRef.dismissed.subscribe(() => {
                    this.selectedItem = null;
                    this.offcanvasRef = null;
                })
            }
        })
    }
}
