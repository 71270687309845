import { CanActivateFn, Router } from '@angular/router';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const UserAuthGuard: CanActivateFn = (route, state): Observable<boolean> => {

    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.getCurrentUser$().pipe(
        switchMap((user) => {
            if (!!user && !!user.microsoftAuth) {
                return of(true);
            }
            router.navigate(['/login']);
            return of(false);
        }),
        catchError(() => {
            router.navigate(['/login']);
            return of(false);
        })
    )
}
