import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { InvoiceDto } from '../../../../../libs/data/src/lib/dto/invoice.dto';

export type InvoiceSortColumn = keyof InvoiceDto | '';
export type InvoiceSortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: InvoiceSortDirection } = { asc: 'desc', desc: 'asc', '': 'asc' };

export interface InvoiceSortEvent {
    column: InvoiceSortColumn;
    direction: InvoiceSortDirection;
}

@Directive({
    selector: 'th[sortable]',
    standalone: true,
    host: {
        '[class.asc]': 'direction === "asc"',
        '[class.desc]': 'direction === "desc"',
        '(click)': 'rotate()',
    },
})
export class NgbdSortableHeader {
    @Input() sortable: InvoiceSortColumn = '';
    @Input() direction: InvoiceSortDirection = '';
    @Output() sort = new EventEmitter<InvoiceSortEvent>();

    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}
