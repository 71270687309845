import { Component, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
    imports: [
        HeaderComponent,
        CommonModule,
        RouterModule,
        RouterOutlet,
    ],
    // providers: [
    // 	ApiService,
    //     AuthService
    // ],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

    constructor(

    ) {
    }

    ngOnInit() {
        console.log('App running on:', environment.url);
    }
}
