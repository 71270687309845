import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-dashboard',
    imports: [CommonModule],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
    public microsoftAuth: boolean | null = null;
    public xeroAuth: boolean | null = null;

    constructor(
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.authService.getUser$().subscribe(
            (user) => {
                if (!!user) {
                    this.microsoftAuth = !!user.microsoftAuth;
                    this.xeroAuth = !!user.xeroAuth;
                }
            },
        );

    }
}
