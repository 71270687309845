<div class="row mt-2">
    <div class="col">
        <span class="logo">cobrel</span>
    </div>
    <div class="col d-flex justify-content-end align-items-end">
        <h3 class="mb-3">Login</h3>
    </div>
</div>

<hr class="mb-4"/>

<div class="row">
    <div class="col">
        <p>Start by clicking Sign In above and use your Microsoft login details.</p>
    </div>
</div>
