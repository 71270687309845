import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { InvoiceDto } from '../../../../../libs/data/src/lib/dto/invoice.dto';
import { InvoiceSortColumn, InvoiceSortDirection } from '../directives/sortable-invoice.directive';

interface SearchResult {
    countries: InvoiceDto[];
    total: number;
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
    sortColumn: InvoiceSortColumn;
    sortDirection: InvoiceSortDirection;
}

function compare(
    v1: string | number | boolean | Date | undefined,
    v2: string | number | boolean | Date | undefined) {
    if (typeof v1 === undefined || typeof v2 === undefined) {
        return 0;
    }
    const v3: string | number | boolean | Date = v1 as string | number | boolean | Date;
    const v4: string | number | boolean | Date = v2 as string | number | boolean | Date;
    return v3 < v4 ? -1 : v3 > v4 ? 1 : 0
}

function sort(countries: InvoiceDto[], column: InvoiceSortColumn, direction: string): InvoiceDto[] {
    if (direction === '' || column === '') {
        return countries;
    } else {
        return [...countries].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

function matches(country: InvoiceDto, term: string, pipe: PipeTransform) {
    return (
        country.invoiceNumber?.toLowerCase().includes(term.toLowerCase()) ||
        country.contactName?.toLowerCase().includes(term.toLowerCase()) ||
        country.total?.toString().includes(term.toLowerCase())
    );
}

@Injectable({ providedIn: 'root' })
export class InvoicesService {
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _items: Array<any> = [];
    private _countries$ = new BehaviorSubject<InvoiceDto[]>([]);
    private _total$ = new BehaviorSubject<number>(0);

    private _state: State = {
        page: 1,
        pageSize: 15,
        searchTerm: '',
        sortColumn: 'invoiceDate',
        sortDirection: '',
    };

    constructor(
        private apiService: ApiService,
        private pipe: DecimalPipe,
    ) {
        this._initInvoices().subscribe({
            next: (items) => {
                this._items = items;
                this._initSearch();
            },
            error: () => {

            }
        });
    }

    get countries$() {
        return this._countries$.asObservable();
    }
    get total$() {
        return this._total$.asObservable();
    }
    get loading$() {
        return this._loading$.asObservable();
    }
    get page() {
        return this._state.page;
    }
    get pageSize() {
        return this._state.pageSize;
    }
    get searchTerm() {
        return this._state.searchTerm;
    }
    get sortColumn() {
        return this._state.sortColumn;
    }
    get sortDirection() {
        return this._state.sortDirection;
    }

    set page(page: number) {
        this._set({ page });
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    set searchTerm(searchTerm: string) {
        this._set({ searchTerm });
    }
    set sortColumn(sortColumn: InvoiceSortColumn) {
        this._set({ sortColumn });
    }
    set sortDirection(sortDirection: InvoiceSortDirection) {
        this._set({ sortDirection });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    private _search(): Observable<SearchResult> {
        const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

        // 1. sort
        let countries = sort(this._items, sortColumn, sortDirection);

        // 2. filter
        countries = countries.filter((country) => matches(country, searchTerm, this.pipe));
        const total = countries.length;

        // 3. paginate
        countries = countries.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        return of({ countries, total });
    }

    private _initInvoices() {
        return this.apiService.getAllInvoices();
    }

    private _initSearch() {

        this._search$
            .pipe(
                // tap(() => this._loading$.next(true)),
                // debounceTime(100),
                // filter(() => this.searchTerm.length > 1),
                switchMap(() => this._search()),
                // delay(200),
                // tap(() => this._loading$.next(false)),
            )
            .subscribe((result) => {
                this._countries$.next(result.countries);
                this._total$.next(result.total);
            });
        this._search$.next();
    }
}
