<nav class="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">

    <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                @if (!!microsoftAuth) {
                    <li class="nav-item" [routerLinkActive]="'active'">
                        <a class="nav-link d-flex align-items-center icon-link-hover" routerLink="/"><i class="bi bi-house-fill"></i> Dashboard</a>
                    </li>
                }
                @if (!!xeroAuth) {
                    <li class="nav-item" [routerLinkActive]="'active'">
                        <a class="nav-link d-flex align-items-center icon-link-hover" routerLink="/inventory"><i class="bi bi-boxes"></i> Inventory</a>
                    </li>
                }
                @if (!!xeroAuth) {
                    <li class="nav-item" [routerLinkActive]="'active'">
                        <a class="nav-link d-flex align-items-center icon-link-hover" routerLink="/invoices"><i class="bi bi-card-list"></i> Invoices</a>
                    </li>
                }
            </ul>
            <ul class="navbar-nav mb-2 mb-lg-0">
                @if (!microsoftAuth) {
                    <li class="nav-item">
                        <a class="nav-link d-flex align-items-center icon-link-hover" href="#" (click)="clickSignIn()">Sign In<i class="bi bi-box-arrow-in-right"></i></a>
                    </li>
                } @else {
                    <li class="nav-item" *ngIf="xeroAuth !== null">
                        @if (microsoftAuth) {
                            @if (xeroAuth) {
                                <a class="nav-link d-flex align-items-center icon-link-hover" href="#" (click)="clickXeroSignOut()">
                                    <i class="bi bi-check-circle-fill"></i>
                                    Xero Connected
                                </a>
                            } @else {
                                <a class="nav-link d-flex align-items-center icon-link-hover" href="#" (click)="clickXeroSignIn()">
                                    <i class="bi bi-x-circle"></i>
                                    Connect to Xero
                                </a>
                            }
                        }
                    </li>
                    <li class="nav-item">
                        <a class="nav-link d-flex align-items-center">
                            <i class="bi bi-person-circle"></i>
                            {{ userName }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link d-flex align-items-center icon-link-hover" href="#" (click)="clickSignOut()">
                            <i class="bi bi-box-arrow-right"></i>
                            Sign Out
                        </a>
                    </li>
                }
            </ul>
        </div>
    </div>
</nav>
