import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { FormsModule } from '@angular/forms';
import { NgbHighlight, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { InvoiceSortEvent, NgbdSortableHeader } from '../../directives/sortable-invoice.directive';
import { InvoicesService } from '../../services/invoices.service';
import { InvoiceDto } from '../../../../../../libs/data/src/lib/dto/invoice.dto';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-invoices',
    imports: [
        AsyncPipe,
        CommonModule,
        FormsModule,
        NgbHighlight,
        NgbPaginationModule,
        NgbdSortableHeader,
    ],
    providers: [
        ApiService,
        InvoicesService,
        DecimalPipe
    ],
    templateUrl: './invoices.component.html',
    styleUrl: './invoices.component.scss'
})
export class InvoicesComponent implements OnInit {
    countries$: Observable<InvoiceDto[]>;
    total$: Observable<number>;

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> | undefined;

    constructor(
        private apiService: ApiService,
        public service: InvoicesService,
        public authService: AuthService
    ) {
        this.countries$ = service.countries$;
        this.total$ = service.total$;
    }

    ngOnInit() {
    }

    onSort({ column, direction }: InvoiceSortEvent) {

        this.headers?.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.sortColumn = column;
        this.service.sortDirection = direction;
    }

    clickSyncInvoices() {
        this.apiService.syncInvoices().subscribe(() => {

        })
    }

    clickSyncInvoiceLineItems() {
        this.apiService.syncInvoiceLineItems().subscribe(() => {

        })
    }
}
