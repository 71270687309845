<div class="row mt-2">
    <div class="col">
        <span class="logo">cobrel</span>
    </div>
    <div class="col d-flex justify-content-end align-items-end">
        <h3 class="mb-3">Inventory</h3>
    </div>
</div>

<hr class="mb-4"/>

<div class="row">
    <div class="col">

        <form>
            <div class="mb-4 row">
                <div class="col-2">
                    <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                        <option [ngValue]="5">5 items per page</option>
                        <option [ngValue]="10">10 items per page</option>
                        <option [ngValue]="15">15 items per page</option>
                        <option [ngValue]="25">25 items per page</option>
                    </select>
                </div>

                <div class="col-8 d-flex justify-content-center">
                    <ngb-pagination
                        [collectionSize]="(total$ | async)!"
                        [(page)]="service.page"
                        [pageSize]="service.pageSize"
                        [maxSize]="10"
                        [rotate]="true"
                        [ellipses]="true"
                        [boundaryLinks]="true">
                    </ngb-pagination>
                </div>

                <div class="col-2">
                    <input
                        id="table-complete-search"
                        type="text"
                        class="form-control"
                        name="searchTerm"
                        placeholder="Search..."
                        [(ngModel)]="service.searchTerm"
                    />
                </div>
            </div>

            <table class="table table-striped" style="font-size: 0.9rem">
                <thead>
                <tr>
                    <th class="text-nowrap" scope="col" sortable="invoiceDateString" (sort)="onSort($event)">Date</th>
                    <th class="text-nowrap" scope="col" sortable="invoiceNumber" (sort)="onSort($event)">Invoice</th> <!-- {{ service.sortColumn === 'code' ? service.sortDirection === 'asc' ? 'asc' : 'des' : '' }} -->
                    <th class="text-nowrap" scope="col" sortable="contactName" (sort)="onSort($event)">Contact Name</th>
                    <th class="text-nowrap" scope="col" sortable="total" (sort)="onSort($event)">Total</th>
                    <th class="text-nowrap" scope="col" sortable="status" (sort)="onSort($event)">Status</th>
                </tr>
                </thead>
                <tbody>
                    @for (country of countries$ | async; track country.id) {
                        <tr>
                            <td>
                                <ngb-highlight [result]="country.invoiceDateString | date: 'dd/MM/yyyy'" [term]="service.searchTerm" />
                            </td>
                            <td>
                                <a class="link-offset-2 link-underline link-underline-opacity-25" role="button" href="https://go.xero.com/app/!VfSQ9/invoicing/view/{{ country.invoiceID }}" target="_blank">
                                    <ngb-highlight [result]="country.invoiceNumber" [term]="service.searchTerm" />
                                </a>
                            </td>
                            <td>
                                <a class="link-offset-2 link-underline link-underline-opacity-25" role="button" href="https://go.xero.com/app/!VfSQ9/contacts/contact/{{ country.contactId }}" target="_blank">
                                    <ngb-highlight [result]="country.contactName" [term]="service.searchTerm" />
                                </a>
                            </td>
                            <td>
                                <ngb-highlight [result]="country.total | number" [term]="service.searchTerm" />
                            </td>
                            <td>
                                <ngb-highlight [result]="country.status" [term]="service.searchTerm" />
                            </td>
                        </tr>
                    } @empty {
                        <tr>
                            <td colspan="5" style="text-align: center">No invoices found</td>
                        </tr>
                    }
                </tbody>
            </table>

        </form>
    </div>
</div>

<div class="row">
    <div class="col">
        <br/>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary me-2" (click)="clickSyncInvoices()">Sync Invoices</button>
            <button type="button" class="btn btn-primary" (click)="clickSyncInvoiceLineItems()" *ngIf="authService.isAdmin$() | async">Sync Invoice Line Items</button>
        </div>
    </div>
</div>
