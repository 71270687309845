<div class="row mt-2">
    <div class="col">
        <span class="logo">cobrel</span>
    </div>
    <div class="col d-flex justify-content-end align-items-end">
        <h3 class="mb-3">Dashboard</h3>
    </div>
</div>

<hr class="mb-4"/>

<p>Welcome to Cobrel Office.</p>

@if (microsoftAuth) {
    @if (xeroAuth) {
        <p>Great, now click Inventory above to lookup items!</p>
    } @else {
        <p>Now click on Connect to Xero above and use your Xero login details and select 'Cobrel' as the organisation you want to connect to.</p>
    }
}
