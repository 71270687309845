import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { UserDto } from '../../../../../../libs/data/src/lib/dto/user.dto';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    imports: [
        CommonModule,
        RouterModule,
        RouterLink,
        RouterLinkActive,
    ]
})
export class HeaderComponent implements OnInit {

    public userName: string = '';
    public microsoftAuth: boolean | null = null;
    public xeroAuth: boolean | null = null;

    private apiUrl = environment.url + environment.api;

    constructor(
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.authService.getUser$().subscribe(
            (user) => {
                if (!!user) {
                    this.userName = user.firstName + ' ' + user.lastName;
                    this.microsoftAuth = !!user.microsoftAuth;
                    this.xeroAuth = !!user.xeroAuth;
                }
            },
        );

    }

    public clickSignIn() {
        this.authService.signIn();
    }

    public clickSignOut() {
        this.authService.signOut();
    }

    public clickXeroSignIn() {
        window.location.href = this.apiUrl + '/xero/login'
    }

    public clickXeroSignOut() {
        if (confirm('Are you sure you want to disconnect Xero?')) {
            this.authService.signOutXero$().subscribe(
                (user: UserDto) => {
                    this.authService.updateUser(user);
                }
            )
        }
    }
}
